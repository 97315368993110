import axios, { AxiosInstance, AxiosResponse } from "axios";
import { riderCityResponseProps } from "../@Types/DRO/RidersCitiesResponse";
export class RiderCitiesApi {
  public static api = new RiderCitiesApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://pre-ondemand.bcykal.com",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  riderCitiesLocalityList(): Promise<AxiosResponse<riderCityResponseProps>> {
    let apiUrl = "/mobycy/on-demand/dashboard/v1/serviceable-cities";
    return this.instance.get<riderCityResponseProps>(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  updateServiceability(
    city: string,
    selectedLocalities: number[],
    unselectedLocalities: number[]
  ): Promise<any> {
    return this.instance
      .post<any>(
        `/mobycy/on-demand/dashboard/v1/polygon/update`,
        {
          city,
          selectedLocalities,
          unselectedLocalities,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
