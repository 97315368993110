import axios, { AxiosInstance, AxiosResponse } from "axios";
import { TrackOrderResponseProps } from "../@Types/DRO/TrackOrderResponse";

export class TrackOrderApi {
  public static api = new TrackOrderApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://pre-ondemand.bcykal.com",
    });
  }

  getToken() {
    return localStorage.getItem("token");
  }

  trackOrderList(
    status: number,
    page: number,
    pageSize: number = 3
  ): Promise<AxiosResponse<TrackOrderResponseProps>> {
    return this.instance.get<TrackOrderResponseProps>(
      `/mobycy/on-demand/dashboard/v1/track-order-list`,
      {
        params: {
          status,
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
}
