import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  OnDutyRiderProps,
  OrderCardInfoResponseProps,
  RiderDataProps,
  RiderSupplyListExportProps,
  RiderSupplyListProps,
} from "../@Types/DRO/RiderSupplyHealthResponse";

export class RiderSupplyHealthApi {
  public static api = new RiderSupplyHealthApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://data.bcykal.com/tracker/ondemand",
      // headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }
  orderCardDetails(locality_id: string[]): Promise<OrderCardInfoResponseProps> {
    let apiUrl = "/rider/supply/help/cards/info";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (daysParams) {
      apiUrl += `?${daysParams}`;
    }
    return this.instance
      .get<OrderCardInfoResponseProps>(apiUrl, {})
      .then((e) => {
        return e.data;
      });
  }
  onDutyRiders(locality_id: string[]): Promise<OnDutyRiderProps> {
    let apiUrl = "/active/inactive/riders";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (daysParams) {
      apiUrl += `?${daysParams}`;
    }
    return this.instance.get<OnDutyRiderProps>(apiUrl, {}).then((e) => {
      return e.data;
    });
  }
  riderData(user_id: string): Promise<RiderDataProps> {
    let apiUrl = "/active/inactive/rider/info";
    return this.instance
      .get<RiderDataProps>(apiUrl, {
        params: {
          user_id,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  exportData(locality_id: string[]): Promise<RiderDataProps> {
    let apiUrl = "/active/rider/details/export";
    const daysParams =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `locality_id=${loc}`).join("&")
        : "";
    if (daysParams) {
      apiUrl += `?${daysParams}`;
    }
    return this.instance.get<RiderDataProps>(apiUrl, {}).then((e) => {
      return e.data;
    });
  }
  riderSupplyList(
    riderType: number,
    locality_id: string[],
    searchParam: string,
    page: number,
    pageSize: number = 20
  ): Promise<RiderSupplyListProps> {
    let apiUrl =
      "https://pre-ondemand.bcykal.com/mobycy/on-demand/dashboard/v1/rider-supply-health-details";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    const polygonIds =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `${loc}`).join(",")
        : "";
    return this.instance
      .get<RiderSupplyListProps>(apiUrl, {
        params: {
          riderType,
          polygonIds,
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  riderSupplyListExport(
    riderType: number,
    locality_id: string[],
    searchParam: string
  ): Promise<RiderSupplyListExportProps> {
    let apiUrl =
      "https://pre-ondemand.bcykal.com/mobycy/on-demand/dashboard/v1/rider-supply-health-details/export";
    if (searchParam) {
      apiUrl += `${searchParam}`;
    }
    const polygonIds =
      locality_id && locality_id.length > 0
        ? locality_id.map((loc) => `${loc}`).join(",")
        : "";
    return this.instance
      .get<RiderSupplyListExportProps>(apiUrl, {
        params: {
          riderType,
          polygonIds,
        },
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
}
